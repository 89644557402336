<template>

      <v-card>
        <v-card-title>
          <span class="headline">Member Card</span>
        </v-card-title>
          
          <v-row align="center" justify="center">
            <v-col class="text-center" cols="4">
              <v-avatar class="mt-4" size="150">
                <v-img :src="user.photoURL"></v-img>
              </v-avatar>
            </v-col>
            <v-col class="text-center" cols="4">
              <v-avatar class="mt-4" size="150">
                <v-img :src="require('@/assets/logo-ssd.png')"></v-img>
              </v-avatar>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="title">{{ user.displayName }}</v-list-item-title>
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>


      </v-card>

</template>

<script>

import {storeMixin} from "@/mixins/store"

export default {
  name: "Profile",

  mixins: [storeMixin],

  data: () => ({

  }),
};
</script>

