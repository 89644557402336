var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[_c('section',{staticClass:"mt-n16",attrs:{"id":"hero"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-img',{attrs:{"src":require('@/assets/header.jpeg'),"min-height":'calc(100vh - ' + _vm.$vuetify.application.top + 'px)'}},[_c('v-theme-provider',{attrs:{"dark":""}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{staticClass:"white--text mx-auto",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"white--text text-center",attrs:{"cols":"12","tag":"h1"}},[_c('span',{staticClass:"font-weight-light",class:[
                    _vm.$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2' ]},[_vm._v(" WELCOME TO ")]),_c('br'),_c('span',{staticClass:"font-weight-black",class:[
                    _vm.$vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4' ]},[_vm._v(" Spanish Society Dubai ")])]),_c('v-btn',{staticClass:"align-self-end",attrs:{"fab":""},on:{"click":function($event){return _vm.$vuetify.goTo('#about')}}},[_c('v-icon',[_vm._v("mdi-chevron-double-down")])],1)],1)],1)],1)],1)],1)],1),_c('section',{attrs:{"id":"about"}},[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v("ABOUT US")]),_c('v-responsive',{staticClass:"mx-auto mb-8",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-responsive',{staticClass:"mx-auto title font-weight-light mb-8",attrs:{"max-width":"720"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.about)}})]),_c('v-avatar',{staticClass:"elevation-12 mb-12",attrs:{"size":"128"}},[_c('v-img',{attrs:{"src":require('@/assets/logo-ssd.png')}})],1)],1),_c('div',{staticClass:"py-12"})],1),_c('section',{staticClass:"grey lighten-3",attrs:{"id":"features"}},[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v("EVENTS")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-row',_vm._l((_vm.features),function(ref,i){
                  var icon = ref.icon;
                  var title = ref.title;
                  var text = ref.text;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"py-12 px-4",attrs:{"color":"grey lighten-5","flat":""}},[_c('v-theme-provider',{attrs:{"dark":""}},[_c('div',[_c('v-avatar',{attrs:{"color":"primary","size":"88"}},[_c('v-icon',{attrs:{"large":""},domProps:{"textContent":_vm._s(icon)}})],1)],1)]),_c('v-card-title',{staticClass:"justify-center font-weight-black text-uppercase",domProps:{"innerHTML":_vm._s(title)}}),_c('v-card-text',{staticClass:"subtitle-1",domProps:{"textContent":_vm._s(text)}})],1)],1)}),1)],1),_c('div',{staticClass:"py-12"})],1),_c('section',{attrs:{"id":"stats"}},[_c('v-parallax',{attrs:{"height":_vm.$vuetify.breakpoint.smAndDown ? 700 : 500,"src":"https://wallpapercave.com/wp/wp3645910.jpg"}},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-row',{staticClass:"mx-auto"},_vm._l((_vm.stats),function(ref){
                  var value = ref[0];
                  var title = ref[1];
return _c('v-col',{key:title,attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"display-3 font-weight-black mb-4",domProps:{"textContent":_vm._s(value)}}),_c('div',{staticClass:"title font-weight-regular text-uppercase",domProps:{"textContent":_vm._s(title)}})])])}),1)],1)],1)],1),(_vm.user && _vm.user.uid == 'FlzKoPY1fmT8lORA3gTw5owL1by1')?_c('section',{attrs:{"id":"sponsors"}},[_c('div',{staticClass:"py-12"}),_c('v-container',[_c('h2',{staticClass:"display-2 font-weight-bold mb-3 text-uppercase text-center"},[_vm._v(" PARTNERS AND SPONSORS ")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-row',_vm._l((_vm.articles),function(ref,i){
                  var src = ref.src;
                  var text = ref.text;
                  var title = ref.title;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-img',{staticClass:"mb-4",attrs:{"src":src,"width":"200"}}),_c('h3',{staticClass:"font-weight-black mb-4 text-uppercase",domProps:{"textContent":_vm._s(title)}}),_c('div',{staticClass:"title font-weight-light mb-5",domProps:{"textContent":_vm._s(text)}})],1)}),1)],1),_c('div',{staticClass:"py-12"})],1):_vm._e(),(_vm.user && _vm.user.uid == 'FlzKoPY1fmT8lORA3gTw5owL1by1')?_c('section',{attrs:{"id":"offers"}},[_c('div',{staticClass:"py-12"}),_c('v-container',[_c('h2',{staticClass:"display-2 font-weight-bold mb-3 text-uppercase text-center"},[_vm._v(" OFFERS AND DISCOUNTS ")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-row',_vm._l((_vm.offers),function(ref,i){
                  var src = ref.src;
                  var text = ref.text;
                  var title = ref.title;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"4"}},[_c('v-img',{staticClass:"mb-4",attrs:{"src":src,"width":"200"}}),_c('h3',{staticClass:"font-weight-black mb-4 text-uppercase",domProps:{"textContent":_vm._s(title)}}),_c('div',{staticClass:"title font-weight-light mb-5",domProps:{"textContent":_vm._s(text)}})],1)}),1)],1),_c('div',{staticClass:"py-12"})],1):_vm._e(),_c('v-sheet',{attrs:{"id":"contact","color":"#333333","dark":"","tag":"section","tile":""}},[_c('div',{staticClass:"py-12"}),_c('Contact'),_c('div',{staticClass:"py-12"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }