import { mapState, mapActions } from "vuex";
//import { mapState, mapActions, mapGetters, mapMutations } from "vuex";

export const storeMixin = {
         computed: {
           ...mapState([
            "user", 
            "userId", 
            "adminUserId"
          ]),
         },

         methods: {
          ...mapActions([
            "checkAuth"
          ]),
        },

};