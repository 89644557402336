<template>
  <v-app>
    
    <v-app-bar app color="grey lighten-4">

      <div class="d-flex align-center">
        <router-link to="/">
          <v-img
            class="mx-2"
            :src="require('@/assets/logo-ssd.png')"
            transition="scale-transition"
            width="40"
          />
        </router-link>

        <span class="font-weight-black hidden-sm-and-down">
          Spanish Society Dubai
        </span>

      </div>

      <v-spacer/>

      <v-btn icon color="pink"
        href="https://www.instagram.com/spanishsocietydubai"
        target="_blank">
        <v-icon>mdi-instagram</v-icon>
      </v-btn>

      <v-btn v-if="user" class="mx-1" color="orange" icon @click.native="$router.push('/blog')">
        <v-icon>mdi-blogger</v-icon>
      </v-btn>

      <v-btn v-if="user" color="red" icon @click="dialog = true">
        <v-icon>mdi-badge-account-horizontal</v-icon>
      </v-btn>

      <v-spacer/>

      <v-btn v-if="!user" color="blue" outlined small @click="login()">
        <span>Login with Google</span>
      </v-btn>


      <v-avatar v-if="user" class="mx-4" size="40">
        <v-img :src="user.photoURL" rel="noreferrer"></v-img>
      </v-avatar>

      <v-btn v-if="user" color="blue" outlined small @click="logout()">
        <span>Logout</span>
      </v-btn>

    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>

    <v-footer
      class="justify-center"
      color="#292929"
      height="100"
    >

      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        &copy; {{ (new Date()).getFullYear() }} — Spanish Society Dubai
      </div>

      <v-spacer/>

      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        Made with <span style="color: #e25555;">&hearts;</span> by 
        <a href="https://www.linkedin.com/in/franmp" style="text-decoration: none">franmp</a>
      </div>

      <v-spacer/>

      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        Follow us
        <v-btn icon color="pink"
          href="https://www.instagram.com/spanishsocietydubai"
          target="_blank">
          <v-icon>mdi-instagram</v-icon>
        </v-btn>
      </div>

    </v-footer>

    <v-row justify="center">
      <v-dialog v-if="user"
        v-model="dialog"
        max-width="500px">

        <Profile/>

      </v-dialog>
    </v-row>

  </v-app>
</template>

<script>

import Profile from '@/components/Profile.vue';

import {storeMixin} from "@/mixins/store"

import {fb, auth} from "@/firebase"

export default {
  name: 'App',

  mixins: [storeMixin],

  components: {
    Profile
  },

  mounted() {
    this.checkAuth()
  },

  data: () => ({
    dialog: false,
  }),

  watch: {

    user(next, prev) {
      console.debug('userId', 'prev', prev, 'next', next)
    },

  },

  methods: {

    async login() {
      try {
        const provider = new fb.auth.GoogleAuthProvider();
        await auth.signInWithPopup(provider);
      } catch (error) {
        console.error(error.message);
      }
    },

    async logout() {
      try {
        await auth.signOut();
      } catch (error) {
        console.error(error.message);
      }
    }

  }
};
</script>
