<template>
  <v-main>
    <section id="hero" class="mt-n16">
      <v-row no-gutters>

        <v-img :src="require('@/assets/header.jpeg')"
          :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'">

          <v-theme-provider dark>
            <v-container fill-height>

              <v-row align="center"
                class="white--text mx-auto"
                justify="center">

                <v-col class="white--text text-center" cols="12" tag="h1">
                  <span class="font-weight-light"
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2',
                    ]"
                  >
                    WELCOME TO
                  </span>

                  <br />

                  <span class="font-weight-black"
                    :class="[
                      $vuetify.breakpoint.smAndDown ? 'display-3' : 'display-4',
                    ]"
                  >
                    Spanish Society Dubai
                  </span>
                </v-col>

                <v-btn fab class="align-self-end"
                  @click="$vuetify.goTo('#about')">

                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>

              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>

    <section id="about">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">ABOUT US</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive max-width="720" class="mx-auto title font-weight-light mb-8">
          <div v-html="about"></div>
        </v-responsive>

        <v-avatar size="128"
            class="elevation-12 mb-12">
          <v-img :src="require('@/assets/logo-ssd.png')"></v-img>
        </v-avatar>

      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="features" class="grey lighten-3">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">EVENTS</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col :key="i" cols="12" md="4"
            v-for="({ icon, title, text }, i) in features">

            <v-card class="py-12 px-4" color="grey lighten-5" flat>
              <v-theme-provider dark>
                <div>
                  <v-avatar color="primary" size="88">
                    <v-icon large v-text="icon"></v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>

              <v-card-title v-html="title" class="justify-center font-weight-black text-uppercase"/>
              <v-card-text class="subtitle-1" v-text="text"/>

            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="stats">
      <v-parallax :height="$vuetify.breakpoint.smAndDown ? 700 : 500"
        src="https://wallpapercave.com/wp/wp3645910.jpg">

        <v-container fill-height>
          <v-row class="mx-auto">
            <v-col :key="title" cols="12" md="4"
              v-for="[value, title] of stats">

              <div class="text-center">
                <div v-text="value" class="display-3 font-weight-black mb-4"/>
                <div v-text="title" class="title font-weight-regular text-uppercase"/>
              </div>

            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>

    <section id="sponsors" v-if="user && user.uid == 'FlzKoPY1fmT8lORA3gTw5owL1by1'">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          PARTNERS AND SPONSORS 
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ src, text, title }, i) in articles"
            :key="i"
            cols="12"
            md="4"
          >
            <v-img
              :src="src"
              class="mb-4"

              width="200"
            ></v-img>

            <h3
              class="font-weight-black mb-4 text-uppercase"
              v-text="title"
            ></h3>

            <div class="title font-weight-light mb-5" v-text="text"></div>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>

    <section id="offers" v-if="user && user.uid == 'FlzKoPY1fmT8lORA3gTw5owL1by1'">
      <div class="py-12"></div>

      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
          OFFERS AND DISCOUNTS
        </h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col
            v-for="({ src, text, title }, i) in offers"
            :key="i"
            cols="12"
            md="4"
          >
            <v-img
              :src="src"
              class="mb-4"

              width="200"
            ></v-img>

            <h3
              class="font-weight-black mb-4 text-uppercase"
              v-text="title"
            ></h3>

            <div class="title font-weight-light mb-5" v-text="text"></div>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>



    <v-sheet id="contact" color="#333333" dark tag="section" tile>
      <div class="py-12"></div>

      <Contact/>

      <div class="py-12"></div>
    </v-sheet>
  
  </v-main>
</template>


<script>

import Contact from '@/components/Contact.vue'

import {storeMixin} from "@/mixins/store"

export default {
  name: "HelloWorld",

  mixins: [storeMixin],

  components: {
    Contact
  },

  data: () => ({
    about: "Spanish Society Dubai was formed in 2020 with the target of gathering, though sports events and activities, the Spanish vibrant community in Dubai, the most dynamic city in the world. " +
            "We strive to give the Spanish community access to organized tournaments and events, while creating a networking space driven by our passion in Sports: Padel, Golf, Football and more! <br/> <br/>" +
            "These events, which will be sponsored by Spanish corporates, will grant access to an amazing and creative way of networking, plus offering the Spanish Society members exclusive advantages. " +
            "Our aim is to promote a healthy lifestyle and bridge the gap between different communities through sports.",

    articles: [
      {
        src: "https://thumbs.dreamstime.com/b/logo-padel-paddle-yellow-ball-paddle-ball-holes-imitating-racket-vector-image-logo-padel-paddle-yellow-ball-108532317.jpg",
        title: "GCC Padel",
        text: "Your online shop with the best quaility and made in Spain rackets",
      },
      {
        src: "https://upload.wikimedia.org/wikipedia/commons/1/13/LaLiga.svg",
        title: "La Liga",
        text: "LaLiga Football Academy [Dubai, Sharjah, Abu Dhabi] is the only scouting soccer school in the UAE",
      },
      {
        src: "https://www.spanishbusinesscouncil.ae/wp-content/uploads/sbc-members/Captura-de-pantalla-2021-01-12-a-las-12.19.10-p.-m.-900x500-1.png",
        title: "Augusta Capital",
        text: "Multisectoral professional services firm",
      },
    ],

    offers: [
      {
        src: "http://2.bp.blogspot.com/-rtyu1huifxs/VB6TD8JxTqI/AAAAAAAARtw/bNuJ2qBCFS4/s1600/Sevilles%2BWafi%2BDubai_013.JPG",
        title: "Sevilles",
        text: "2x1 in Friday brunch",
      },
      {
        src: "https://images.squarespace-cdn.com/content/5f1e6f0a2056001e992183b5/1597057843875-42CH0GIMD612UW0KLVYA/Lola+Logo.png?format=1500w&content-type=image%2Fpng",
        title: "Lola Taberna",
        text: "2x1 Happy hour Fridays 20-22",
      },
      {
        src: "https://whatson.ae/wp-content/uploads/2021/06/Padel-pro.jpg",
        title: "Padel Pro",
        text: "Voucher 10 matches for only 600 AED",
      },
    ],

    features: [
      {
        icon: "mdi-account-group-outline",
        title: "Vibrant <br/> Community",
        text: "Join the Dubai Spanish community!",
      },
      {
        icon: "mdi-update",
        title: "Frequent <br/> Tournaments",
        text: "Do you play padel? golf? our tournaments are for you, no matter if you are a Pro or a Beginner",
      },
      {
        icon: "mdi-racquetball",
        title: "Boost your level",
        text: "Find Spanish teachers that boost your level to the maximun level",
      },
    ],
    stats: [
      ["70+", "Players"],
      ["3", "Tournaments"],
      ["2", "Categories"],
    ],
  }),
};
</script>
