import firebase from "firebase/app";
import "firebase/auth";


const firebaseConfig = {
  apiKey: "AIzaSyABoyv3uS4yMQ2JCgSuXKi3mXh2I__GZnc",
  authDomain: "spanish-society-dubai.firebaseapp.com",
  projectId: "spanish-society-dubai",
  storageBucket: "spanish-society-dubai.appspot.com",
  messagingSenderId: "1054582229400",
  appId: "1:1054582229400:web:e3ef825dd3bcc05c3fc3ed",
  measurementId: "G-JLHG2M2FP1"
};

firebase.initializeApp(firebaseConfig);

export const fb = firebase;
export const auth = firebase.auth();

