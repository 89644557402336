<template>
  <v-container>

    <v-snackbar v-model="snackbar" right top color="primary darken-2">
      Thanks for contacting us
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false" >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">
      Contact
    </h2>

    <v-responsive class="mx-auto mb-12" width="56">
      <v-divider class="mb-1"></v-divider>
      <v-divider></v-divider>
    </v-responsive>

    <v-theme-provider light>
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="name" 
            :error-messages="nameErrors"
            required
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
            flat label="Name*" solo></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-text-field v-model="email" 
            :error-messages="emailErrors"
            required
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
            flat label="Email*" solo></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea v-model="message" 
            :error-messages="messageErrors"
            required
            @input="$v.message.$touch()"
            @blur="$v.message.$touch()"
            flat label="Message*" solo></v-textarea>
        </v-col>

        <v-col class="mx-auto" cols="auto">
          <v-btn  x-large 
            :disabled="formInvalid"
            :loading="loading"
            @click="sendEmail()">
            Submit
          </v-btn>
        </v-col>
      </v-row>
    </v-theme-provider>
  </v-container>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'


export default {
  name: "Contact",

  mixins: [validationMixin],

  validations: {
    name: { required },
    email: { required, email },
    message: { required },
  },

  data: () => ({
    name: "",
    email: "",
    message: "",
    loading: false,
    snackbar: false
  }),

  computed: {
    nameErrors () {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.required && errors.push('Name is required.')
      return errors
    },
    emailErrors () {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Must be valid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    messageErrors () {
      const errors = []
      if (!this.$v.message.$dirty) return errors
      !this.$v.message.required && errors.push('Message is required.')
      return errors
    },
    formInvalid () {
      return this.nameErrors.length > 0 || this.emailErrors.length > 0 || this.messageErrors.length > 0
    },
    canSendEmail () {
      return this.nameErrors.length == 0 && this.emailErrors.length == 0 && this.messageErrors.length == 0
        && this.name.length > 0 && this.email.length > 0 && this.message.length > 0
    }
  },

  methods: {
    sendEmail() {
      this.$v.$touch()

      if (this.canSendEmail) {

        this.loading = true

        this.$loadScript("https://smtpjs.com/v3/smtp.js")
          .then(() => {

            window.Email && window.Email.send({

              SecureToken: "bd55d6e8-b338-4b74-9d44-ece4058ea7af",
              To: "spanishsocietydubai@gmail.com",
              From: this.email,
              Subject: "spanishsocietydubai contact form: " + this.name,
              Body: this.message,

            }).then(() => {

              this.loading = false
              this.snackbar = true

              this.$v.$reset()
              this.name = ''
              this.email = ''
              this.message = ''
            });

          })

      }
    },
  },
};
</script>

