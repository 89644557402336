import Vue from 'vue'
import Vuex from 'vuex'


import { auth } from "@/firebase";


Vue.use(Vuex)

export default new Vuex.Store({

  //strict: true,

  state: {

    user: null,
    userId: null,
    adminUserId: "FlzKoPY1fmT8lORA3gTw5owL1by1",

  },

  mutations: {

    setUser(state, user) {
      state.user = user;

      if (user != null) {
        state.userId = user.uid;
      }
    },

  },

  actions: {

    checkAuth({ commit }) {
      auth.onAuthStateChanged((user) => {
        if (user) {
          commit("setUser", user);
        } else {
          commit("setUser", null);
        }
      });
    },

  },
  modules: {},
});
